import { useMemo } from 'react';

import { useDebounceValue } from 'usehooks-ts';

import { useTheme } from '@mui/material';

import { useRefDimensions } from './useRefDimensions';

export const useContainerMedia = (node: HTMLDivElement | null) => {
  const theme = useTheme();
  const { width } = useRefDimensions(node);
  const debouncedWidth = useDebounceValue(width, 400);
  const media = useMemo(() => {
    if (width >= theme.breakpoints.values.xl) {
      return 'xl';
    }
    if (width >= theme.breakpoints.values.lg) {
      return 'lg';
    }
    if (width >= theme.breakpoints.values.md) {
      return 'md';
    }
    if (width >= theme.breakpoints.values.sm) {
      return 'sm';
    }
    if (width > 0) {
      return 'xs';
    }
    return null;
  }, [debouncedWidth]);
  return media;
};
