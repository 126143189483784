export enum PlausibleEvents {
  FEEDBACK_OPEN = 'feedback_open',

  DOSSIER_ADD_OPTION = 'profile_dossier_add_option',
  DOSSIER_DELTA_MONITORING = 'profile_dossier_delta_monitoring',
  DOSSIER_MILITARY_FIND_UNIT_CHECKBOX = 'profile_dossier_military_find_checkbox',
  DOSSIER_MILITARY_USE_CUSTOM_UNIT = 'profile_dossier_military_use_custom_unit',
  DOSSIER_MILITARY_ADD_ROV_TAG = 'profile_dossier_military_add_rov_tag',

  MAP_INITIALIZATION = 'map_initialization',
  MAP_LAYERS_DEEPSTATE_SWITCH = 'map_layers_deepstate_switch_on/off',
  MAP_LAYERS_DEEPSTATE_OCCUPATION_SWITCH = 'map_layers_deepstate_occupation_switch_on/off',
  MAP_LAYERS_DEEPSTATE_FORTIFICATIONS_SWITCH = 'map_layers_deepstate_fortifications_switch_on/off',
  MAP_LAYERS_DEEPSTATE_POINTS_SWITCH = 'map_layers_deepstate_points_switch_on/off',
  MAP_LAYERS_NAI_SWITCH = 'map_layers_nai_switch_on/off',
  MAP_DEBUG_GRID_SWITCH = 'map_debug_grid_switch_on/off',
  MAP_LAYERS_ZONES_SWITCH = 'map_layers_zones_switch_on/off',
  MAP_LAYERS_POINTS_SWITCH = 'map_layers_points_switch_on/off',
  MAP_SATELLITE_SELECT = 'map_satelite_select',
  MAP_STREETS_SELECT = 'map_streets_select',
  MAP_ARCGISON_SELECT = 'map_arcgison_select',
  MAP_VARIANT_CHANGED = 'map_variant_changed',
  MAP_SEARCH_BY_COORDINATES = 'map_search_by_coordinates',
  MAP_SEARCH_AUTOCOMPLETE_SELECT = 'map_search_autocomplete_select',
  MAP_MODE_ACTIONS_CONTROL_CHANGE = 'map_mode_actions_control',

  SCAN_PROFILE_LINK = 'location_scan_id_link',
  SCAN_DOSSIER_LINK = 'location_scan_dossier_link',
  SCAN_CLARIFYING_LINK = 'location_scan_clarifying_link',
  SCAN_LOCATION_LINK = 'location_scan_location_link',
  SCAN_FILTER_BUTTON = 'location_scan_filter_filter_button',
  SCAN_SIMILAR_BUTTON = 'location_scan_similar_link',
  SCAN_SHOW_ALL_BUTTON = 'location_scan_show_all_link',
  SCAN_ARCHIVE_GENERATE_BUTTON = 'location_scan_archive_generate',
  SCAN_PERIODIC_CANCEL = 'location_periodic_scan_list_scan_stop',
  SCAN_LIST_STATUS_CHANGE = 'location_scan_list_status_change',
  SCAN_LIST_MARKER_CHANGE = 'location_scan_list_marker_change',
  SCAN_LIST_SCAN_REPEAT = 'location_scan_list_scan_repeat',
  SCAN_LIST_SCAN_CLARIFYING = 'location_scan_list_scan_clarifying',
  SCAN_LIST_SCAN_REMOVE = 'location_scan_list_scan_remove',
  SCAN_LIST_SCAN_CANCEL = 'location_scan_list_scan_cancel',

  SEARCH_PHOTOS_CREATE = 'search_photos_create',

  SEARCH_ON_MAP_GEOPOPUP_OPEN = 'search_on_map_geopopup_open',

  SETTINGS_COLLAPSE_PHOTOS_SWITCH = 'settings_collapse_photos_on/off',
  SETTINGS_THEME_MODE = 'settings_theme_mode',
  SETTINGS_THEME_SWITCH = 'settings_theme_switch',

  MANAGE_USER_TAGS_OPEN_MODAL = 'manage_user_tags_open_modal',
  MANAGE_USER_TAGS_RESET = 'manage_user_tags_reset',
  MANAGE_USER_TAGS_CLOSE = 'manage_user_tags_close',
  MANAGE_USER_TAGS_SAVE = 'manage_user_tags_save',

  PERSON_PROFILE_CARD_MILITARY_EXPERIENCES = 'band_profile_card_open_military_experiences',

  BAND_VIP_CHANGE = 'band_vip_change',
  BAND_TAG_BTN_EDIT_CLICK = 'band_tag_btn_edit_click',
  BAND_SOCIAL_BTN_ADD_CLICK = 'band_social_btn_add_click',
  BAND_GENERAL_INFO_EDIT_CLICK = 'band_general_info_edit_click',
  BAND_AVATAR_EDIT_CLICK = 'band_avatar_edit_click',
  BAND_ADD_NEW_FIRST_OPTION_CLICK = 'band_add_new_first_option_click',
  BAND_OPTION_DELETE_SUBMIT = 'band_option_delete_submit',
  BAND_OPTION_UPDATE_SUBMIT = 'band_option_update_submit',
  BAND_OPTION_ORDER_CHANGE = 'band_option_order_change',
  BAND_PHOTO_TAG_DELETE_SUBMIT = 'band_photo_tag_delete_submit',

  BAND_LOCATION_TRAJECTORY = 'band_location_trajectory',
  BAND_LOCATION_BY_DATE = 'band_location_by_date',
  BAND_LOCATION_UPDATE_FILTERS = 'band_location_update_filters',

  FILTER_PINNED_TOGGLE_CLICK = 'filter_pinned_toggle_click',

  CREATE_BAND = 'create_band',

  BANDS_REPORT_EXPORT = 'band_report_export',

  BANDS_GROUP_MAP = 'bands_group_map',

  LINKED_BANDS_ADD = 'linked_bands_add',

  TELEZIP_SEARCH_STATUS_CHANGE = 'telezip_search_status_change',
  TELEZIP_SEARCH_MODE_CHANGE = 'telezip_search_mode_change',
  TELEZIP_DRAWER_FILTER_PAYLOAD = 'telezip_drawer_filter_payload',
  TELEZIP_AD_HOC_REQUEST = 'telezip_ad_hoc_request',

  VKOLUPAILO_EXPORT_TO_CSV = 'vkolupailo_export_to_csv',
  VKOLUPAILO_SEARCH = 'vkolupailo_search',
  VKOLUPAILO_GEOSEARCH_SUBMIT = 'vkolupailo_geosearch_submit',
  VKOLUPAILO_GEOSEARCH_OPEN_POPUP = 'vkolupailo_geosearch_open_popup',
  VKOLUPAILO_GEOSEARCH_FETCH_MORE = 'vkolupailo_geosearch_fetch_more',
  VKOLUPAILO_CHECK_CONNECTOR = 'vkolupailo_check_connector',
  VKOLUPAILO_REFRESH_PROFILE = 'vkolupailo_refresh_profile',

  ALPINE_QUEST_GEO_JSON_FILTER_PAYLOAD = 'alpine_quest_geo_json_filter_payload',
  ALPINE_QUEST_CREATE_REQUEST = 'alpine_quest_create_request',
  ALPINE_QUEST_UPDATE_REQUEST = 'alpine_quest_update_request',

  MAMBA_PROFILE_TRACKING = 'mamba_profile_tracking',
}

export enum PlausiblePageView {
  CLEARVIEW_PAGE_VIEW = 'profile_clearview_pageview',
  SEARCH_PHOTOS_RESULTS_PAGEVIEW = 'search_photos_results_pageview',

  SEARCH_USERS_TAB_PERSONAL = 'profiles_search_personal_pageview',
  SEARCH_USERS_TAB_TAGS = 'profiles_search_tags_pageview',
}
