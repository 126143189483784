import { skipToken } from '@reduxjs/toolkit/dist/query';

import { DossierSocialConnectorId as ConnectorId } from 'enums/dossier';

import { useBandConnectors } from './useBandConnectors';

type UseProfileIdsFromConnectorReturnType = {
  profileIds: number[];
  isConnectorsLoading: boolean;
};

export const useProfileIdsFromConnector = (
  bandId: number | typeof skipToken,
  connectorId: ConnectorId
): UseProfileIdsFromConnectorReturnType => {
  const { connectors, isConnectorsLoading } = useBandConnectors(bandId, connectorId);
  const profileIds = connectors.map(({ profileId }) => profileId ?? 0).filter(Boolean);

  return { profileIds, isConnectorsLoading };
};
