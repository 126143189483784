import { useMemo } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { uniqBy } from 'lodash';

import { useGetBandFullProfileQuery } from 'api/services/dossierSearch';
import { useGetMambaProfilesQuery } from 'api/services/mamba';
import { useGetUserTagsQuery } from 'api/services/tags';
import { useGetVKGeoPhotosQuery } from 'api/services/vk';
import { DossierSocialConnectorId } from 'enums/dossier';
import { FetchErrorData } from 'types/api.type';
import { BandTag } from 'types/tags.type';
import { extractConnectorIds } from 'utils/dossier';

type UseAggregatedTagsParams = {
  bandIds: number[];
  skip?: boolean;
};

type UseAggregatedTagsResult = {
  tags?: Record<number, BandTag[]>;
  isLoading: boolean;
  error?: FetchErrorData | SerializedError;
};

export const useAggregatedTags = ({
  bandIds,
  skip,
}: UseAggregatedTagsParams): UseAggregatedTagsResult => {
  const bandResult = useGetBandFullProfileQuery(skip ? skipToken : { dossierIds: bandIds });
  const bands = bandResult.data?.results;

  const vkIds = useMemo(
    () => extractConnectorIds(bands, DossierSocialConnectorId.VKONTAKTE),
    [bands]
  );

  const mambaIds = useMemo(
    () => extractConnectorIds(bands, DossierSocialConnectorId.MAMBA),
    [bands]
  );

  const shouldFetchVKTags = !!vkIds?.length;

  const vkResult = useGetVKGeoPhotosQuery({ profileVkIds: vkIds }, { skip: !shouldFetchVKTags });

  const shouldFetchMambaTags = !!mambaIds?.length;

  const mambaResult = useGetMambaProfilesQuery({ mambaIds }, { skip: !shouldFetchMambaTags });

  const shouldFetchUserTags = shouldFetchVKTags || shouldFetchMambaTags;
  const userTagsResult = useGetUserTagsQuery(shouldFetchUserTags ? undefined : skipToken);

  const tags = useMemo(() => {
    if (
      !bands ||
      (shouldFetchUserTags && !userTagsResult.data) ||
      (shouldFetchVKTags && !vkResult.data) ||
      (shouldFetchMambaTags && !mambaResult.data)
    ) {
      return undefined;
    }

    return bands.reduce<Record<number, BandTag[]>>((acc, band) => {
      const bandVkIds = extractConnectorIds([band], DossierSocialConnectorId.VKONTAKTE);

      const bandVkTags = vkResult.data
        ?.filter(({ ownerVkId }) => bandVkIds?.includes(ownerVkId))
        .reduce<string[]>(
          (vkTagsAcc, photo) => (photo.tags ? [...vkTagsAcc, ...photo.tags] : vkTagsAcc),
          []
        );

      const bandMambaIds = extractConnectorIds([band], DossierSocialConnectorId.MAMBA);

      const bandMambaTags = mambaResult.data?.results
        .filter(({ mambaId }) => bandMambaIds?.includes(mambaId))
        .reduce<string[]>((acc, profile) => [...acc, ...(profile.youscanTags ?? [])], []);

      const tagNames = [...(bandVkTags ?? []), ...(bandMambaTags ?? [])];
      const externalServicesTags = userTagsResult.data
        ?.filter(
          ({ name }) =>
            name.startsWith('youscan/') && tagNames?.includes(name.slice(8).replaceAll('_', ' '))
        )
        .map((tag) => ({ ...tag, removable: false }));

      return {
        ...acc,
        [band.id]: uniqBy([...band.tags, ...(externalServicesTags ?? [])], 'id').toSorted(
          (a, b) => a.priority - b.priority
        ),
      };
    }, {});
  }, [
    userTagsResult.data,
    mambaResult.data,
    vkResult.data,
    bands,
    shouldFetchUserTags,
    shouldFetchVKTags,
    shouldFetchMambaTags,
  ]);

  return {
    tags,
    isLoading: [bandResult, userTagsResult, vkResult, mambaResult].some(
      ({ isLoading }) => isLoading
    ),
    error: [bandResult, userTagsResult, vkResult, mambaResult].find(({ error }) => error)?.error,
  };
};
