import { useEffect, useState } from 'react';

export const useRefDimensions = (current?: HTMLDivElement | null) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (current) {
      resizeObserver = new ResizeObserver((elements) => {
        const component = elements[0]?.contentRect;
        setDimensions({ width: component?.width, height: component?.height });
      });
      resizeObserver.observe(current);
    }
    return () => resizeObserver?.disconnect();
  }, [current]);
  return dimensions;
};
