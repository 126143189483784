import { useState, useCallback, useEffect, RefObject } from 'react';

import { VirtuosoHandle } from 'react-virtuoso';

const useVirtuosoNavigation = (
  count: number,
  virtuosoRef: RefObject<VirtuosoHandle>,
  hasMore?: boolean,
  loadMore?: () => void
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const canPrev = useCallback(() => currentIndex !== 0, [currentIndex]);
  const canNext = useCallback(
    () => hasMore || currentIndex < count - 1,
    [hasMore, currentIndex, count]
  );
  const prev = useCallback(() => {
    setCurrentIndex((currentIndex) => currentIndex - 1);
    virtuosoRef.current?.scrollToIndex(currentIndex - 1);
  }, [currentIndex]);

  const next = useCallback(() => {
    if (hasMore && currentIndex === count - 1) {
      loadMore?.();
    }

    setCurrentIndex(currentIndex + 1);
    virtuosoRef.current?.scrollToIndex(currentIndex + 1);
  }, [currentIndex, hasMore, count, loadMore]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLElement &&
        (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA')
      ) {
        return;
      }
      const isNavigatePrev = e.code === 'ArrowUp' || e.code === 'ArrowLeft';
      const isNavigateNext = e.code === 'ArrowDown' || e.code === 'ArrowRight';

      if (isNavigateNext || isNavigatePrev) {
        e.preventDefault();

        if (isNavigatePrev && canPrev()) {
          prev();
        }

        if (isNavigateNext && canNext()) {
          next();
        }
      }
    },
    [canPrev, canNext, prev, next]
  );

  useEffect(() => {
    if (!currentIndex) return;
    virtuosoRef.current?.scrollToIndex({
      index: currentIndex,
      align: 'start',
      behavior: 'smooth',
    });
  }, [currentIndex, virtuosoRef.current]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return {
    currentIndex,
    setCurrentIndex,
    canPrev,
    canNext,
    prev,
    next,
  };
};

export default useVirtuosoNavigation;
