import type { BaseQueryFn, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { difference, intersection, omit } from 'lodash';

import { api } from 'api';
import { APP_BE_URL } from 'constants/appBeUrl';
import { DossierSocialConnectorId } from 'enums/dossier';
import { ErrorData, FetchErrorData, PaginatedResponse } from 'types/api.type';
import {
  VkProfile,
  VkResolveResult,
  VkSearchCapacity,
  VkSearchPhoneResult,
  VkSearchPhonesResultsQuery,
  VkSearchPhones,
  VkSearchPhonesCreatePayload,
  VkSearchQuery,
  VkSearchesPhonesQuery,
  VkGeoPhotoSearchRequest,
  VkGeoPhoto,
} from 'types/vk.type';
import { getS3PhotoPath } from 'utils/images';

import { dossierSearch } from './dossierSearch';

const VK_API_BASE_URL = `${APP_BE_URL}/vkolupailo/api/v1`;

const CACHE_LIFETIME = 60 * 60 * 24;

const transformImageLink = (link: string | null) =>
  link && link.startsWith('/') ? `${APP_BE_URL}${link}` : link;

const transformImages = (profile: VkProfile): VkProfile => ({
  ...profile,
  avatarLink: transformImageLink(profile.avatarLink),
  ...(profile.photos && {
    photos: profile.photos.map((photo) => ({
      ...photo,
      link: transformImageLink(photo.link),
      path: getS3PhotoPath(photo.link),
    })),
  }),
});

const findAndAnnotateName = (idToFind: number | null, related: VkProfile[]) => {
  const relativeProfile = related.find(({ id }) => id === idToFind);
  return relativeProfile
    ? [relativeProfile.firstName, relativeProfile.lastName].filter(Boolean).join(' ')
    : null;
};

const enrichRelatives = async <QueryArg extends Record<string, unknown>>(
  arg: QueryArg,
  queryLifecycleApi: QueryLifecycleApi<
    QueryArg,
    BaseQueryFn,
    VkProfile[] | PaginatedResponse<VkSearchPhoneResult>,
    typeof api.reducerPath
  >
) => {
  let patchCache;

  if (arg.ignoreRelatives) {
    return;
  }

  const { dispatch, queryFulfilled, updateCachedData } = queryLifecycleApi;

  try {
    const { data } = await queryFulfilled;
    const results = 'count' in data ? data.results : data;

    const ids = results?.reduce<number[]>((acc, result) => {
      const profile = 'profile' in result ? result.profile : result;

      const relatives = [
        profile?.relationPartnerId,
        ...(profile?.relatives || []).map(({ id }) => id),
      ].filter((val): val is number => !!val);
      return [...acc, ...relatives];
    }, []);

    if (!ids.length) {
      return;
    }

    const { data: relativesFromDB } = (await dispatch(
      // @ts-ignore
      api.endpoints.getVKProfilesByIdsAndNames.initiate({
        vkIds: ids.join(','),
        ignoreRelatives: true,
      })
    )) as QueryReturnValue<VkProfile[], FetchErrorData, undefined>;

    let relatives = relativesFromDB ? [...relativesFromDB] : [];

    const notFoundIds = difference(
      ids,
      relatives.map(({ id }) => id)
    );

    if (notFoundIds.length) {
      const { data: relativesFromVK }: { data: VkProfile[] } = await dispatch(
        // @ts-ignore
        api.endpoints.getVKProfiles.initiate({
          ids,
          ignoreRelatives: true,
        })
      );

      relatives = [...relatives, ...relativesFromVK];
    }

    if (relatives.length) {
      patchCache = updateCachedData((cache) => {
        const list = 'count' in cache ? cache.results : cache;

        list.forEach((result) => {
          const profile = 'profile' in result ? result.profile : result;

          if (!profile) {
            return;
          }

          const name = findAndAnnotateName(profile.relationPartnerId, relatives);

          if (name) {
            profile.relationPartnerName = name;
          }

          profile.relatives?.forEach((relative) => {
            const name = findAndAnnotateName(relative.id, relatives);

            if (name) {
              relative.name = name;
            }
          });
        });
      });
    }
  } catch {
    patchCache?.undo();
  }
};

export const vkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVKIDByScreenName: build.query<VkResolveResult, string>({
      query: (screenName) => ({
        url: `${VK_API_BASE_URL}/vk/profiles/resolve_screen_name`,
        method: 'post',
        body: { screenName },
      }),
      providesTags: (response) => {
        return response ? [{ type: 'vkResolveScreenName', id: response.screenName }] : [];
      },
    }),
    getVKGroupSubscribers: build.query<number[], string>({
      query: (vkId) => ({
        url: `${VK_API_BASE_URL}/vk/groups/search_members`,
        method: 'post',
        body: { vkId },
      }),
      providesTags: (response, error, arg) => {
        return response ? [{ type: 'vkGroupSubscribers', id: arg }] : [];
      },
    }),
    getVKProfilesByIdsAndNames: build.query<
      VkProfile[],
      { vkIds?: string; screenNames?: string; ignoreRelatives?: boolean }
    >({
      query: (params) => ({
        url: `${VK_API_BASE_URL}/vk/profiles`,
        params: omit(params, 'ignoreRelatives'),
      }),
      providesTags: (_response, _error, { vkIds, screenNames }) => {
        return [...(vkIds?.split(',') || []), ...(screenNames?.split(',') || [])].map(
          (identifier) => ({
            type: 'vkProfileFromDB' as const,
            id: identifier,
          })
        );
      },
      onQueryStarted: enrichRelatives,
      transformResponse: (data: VkProfile[]) => data.map(transformImages),
    }),
    getVKProfile: build.query<VkProfile, string | number>({
      queryFn: async (vkId, { dispatch }) => {
        const { data: profiles, error } = (await dispatch(
          vkApi.endpoints.getVKProfiles.initiate({
            ids: [vkId.toString()],
          })
        )) as QueryReturnValue<VkProfile[], FetchErrorData, undefined>;

        return { data: profiles?.[0], error } as QueryReturnValue<
          VkProfile,
          FetchErrorData,
          undefined
        >;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(api.util.invalidateTags([{ type: 'vkProfileFromDB', id }]));
      },
    }),
    getVKProfiles: build.query<VkProfile[], VkSearchQuery>({
      query: (query) => {
        const validateStatus = (response: Response, result: unknown) =>
          response.status === 200 && result !== null;

        if ('phone' in query) {
          return {
            validateStatus,
            url: `${VK_API_BASE_URL}/vk/profiles/search_by_phone`,
            method: 'post',
            body: {
              phone: query.phone,
            },
          };
        }

        if ('ids' in query) {
          const { page, pageSize } = query;

          return {
            validateStatus,
            method: 'post',
            ...(query.ids.length === 1
              ? {
                  url: `${VK_API_BASE_URL}/vk/profiles/search`,
                  body: {
                    vkId: query.ids[0],
                  },
                }
              : {
                  url: `${VK_API_BASE_URL}/vk/profiles/bulk_search`,
                  body: {
                    vkIds:
                      page && pageSize
                        ? query.ids.slice((page - 1) * pageSize, page * pageSize)
                        : query.ids,
                  },
                }),
          };
        }

        throw new Error('Either phone or ids should be provided');
      },
      onQueryStarted: enrichRelatives,
      transformErrorResponse: (error) => error.data as ErrorData,
      transformResponse(data) {
        const isSingle = !Array.isArray(data);
        const transformed = isSingle ? [data] : data;

        return transformed.map((profile) => ({
          ...transformImages(profile),
          needsEnrich: !isSingle && !profile.dtParseFull && !profile.deactivated,
        }));
      },
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: ['vkSearchResults'],
    }),
    createVKSearchByNumbers: build.mutation<VkSearchPhones, VkSearchPhonesCreatePayload>({
      query: (payload) => ({
        url: `${VK_API_BASE_URL}/search-phones`,
        method: 'post',
        body: payload,
      }),
      invalidatesTags: ['vkSearchByNumbers'],
    }),
    getVKSearchesByNumbers: build.query<PaginatedResponse<VkSearchPhones>, VkSearchesPhonesQuery>({
      query: ({ createdAtGte, createdAtLte, ...params }) => ({
        url: `${VK_API_BASE_URL}/search-phones`,
        params: {
          ...params,
          created_at__gte: createdAtGte,
          created_at__lte: createdAtLte,
        },
      }),
      providesTags: ['vkSearchByNumbers'],
    }),
    getVKSearchByNumbers: build.query<VkSearchPhones, number>({
      query: (id) => `${VK_API_BASE_URL}/search-phones/${id}`,
      providesTags: (_res, _err, id) => [{ type: 'vkSearchByNumbers', id }],
    }),
    getVKSearchByNumbersVkIds: build.query<number[], number>({
      query: (id) => `${VK_API_BASE_URL}/search-phones/${id}/vk-ids`,
      providesTags: (_res, _err, id) => [{ type: 'vkSearchByNumbersVkIds', id }],
    }),
    cancelVKSearchByNumbers: build.mutation<void, number>({
      query: (id) => ({
        url: `${VK_API_BASE_URL}/search-phones/${id}/cancel`,
        method: 'post',
      }),
      invalidatesTags: (_res, _err, id) => ['vkSearchByNumbers', { type: 'vkSearchByNumbers', id }],
    }),
    resumeVKSearchByNumbers: build.mutation<void, number>({
      query: (id) => ({
        url: `${VK_API_BASE_URL}/search-phones/${id}/resume`,
        method: 'post',
      }),
      invalidatesTags: (_res, _err, id) => ['vkSearchByNumbers', { type: 'vkSearchByNumbers', id }],
    }),
    getVKSearchByNumbersResults: build.query<
      PaginatedResponse<VkSearchPhoneResult>,
      VkSearchPhonesResultsQuery
    >({
      queryFn: async (params, { dispatch }, _extraOptions, baseQuery) => {
        let vkIds = params.vkIds
          ?.trim()
          .split(/\s*,\s*/)
          .map(Number);

        if (params.withBand) {
          const { data: allVkIds } = (await baseQuery({
            url: `${VK_API_BASE_URL}/search-phones/${params.id}/vk-ids`,
          })) as { data: number[] };

          const { data: bands } = await dispatch(
            dossierSearch.endpoints.getBandsByConnector.initiate({
              connectors: allVkIds.map((vkId) => ({
                connectorType: DossierSocialConnectorId.VKONTAKTE,
                profileId: vkId,
              })),
            })
          );

          const vkIdsWithBand =
            bands?.reduce<number[]>((acc, linked, i) => {
              return linked.length ? [...acc, allVkIds[i]] : acc;
            }, []) ?? [];

          vkIds = vkIds?.length ? intersection(vkIdsWithBand, vkIds) : vkIdsWithBand;

          if (vkIds.length === 0) {
            vkIds = [1]; // work-around for the case when there is no intersection nor linked bands
          }
        }

        const vkIdsComponent = new URLSearchParams();
        vkIds?.forEach((vkId) => vkIdsComponent.append('vk_ids', vkId.toString()));

        const results = (await baseQuery({
          url: `${VK_API_BASE_URL}/search-phones/${params.id}/results?${vkIdsComponent}`,
          params: omit(params, 'vkIds'),
        })) as { data: PaginatedResponse<VkSearchPhoneResult> };

        results.data.results.forEach((result) => {
          if (result.profile) {
            result.profile = transformImages(result.profile);
          }
        });

        return results;
      },
      onQueryStarted: enrichRelatives,
      providesTags: (_res, _err, params) => [{ type: 'vkSearchByNumbersResults', id: params.id }],
    }),

    getVKGeoPhotos: build.query<VkGeoPhoto[], VkGeoPhotoSearchRequest>({
      query: (payload) => ({
        url: `${VK_API_BASE_URL}/vk/profiles/search/geo-photo`,
        method: 'post',
        body: payload,
      }),
      transformResponse(data: VkGeoPhoto[]) {
        return data.map((photo) => ({
          ...photo,
          path: getS3PhotoPath(photo.link),
          isClub: false,
        }));
      },
    }),

    getVKGroupGeoPhotos: build.query<VkGeoPhoto[], VkGeoPhotoSearchRequest>({
      query: (payload) => ({
        url: `${VK_API_BASE_URL}/vk/groups/search/geo-photo`,
        method: 'post',
        body: payload,
      }),
      transformResponse(data: VkGeoPhoto[]) {
        return data.map((photo) => ({
          ...photo,
          path: getS3PhotoPath(photo.link),
          isClub: true,
        }));
      },
    }),

    getVKSearchCapacity: build.query<VkSearchCapacity, void>({
      query: () => `${VK_API_BASE_URL}/capacity/check-phones`,
    }),
  }),
});

export const {
  useGetVKIDByScreenNameQuery,
  useLazyGetVKIDByScreenNameQuery,
  useGetVKGroupSubscribersQuery,
  useGetVKProfilesQuery,
  useGetVKProfilesByIdsAndNamesQuery,
  useLazyGetVKProfilesByIdsAndNamesQuery,
  useLazyGetVKProfileQuery,
  useLazyGetVKProfilesQuery,
  useGetVKSearchesByNumbersQuery,
  useGetVKSearchByNumbersQuery,
  useGetVKSearchByNumbersVkIdsQuery,
  useGetVKSearchByNumbersResultsQuery,
  useCancelVKSearchByNumbersMutation,
  useResumeVKSearchByNumbersMutation,
  useGetVKGeoPhotosQuery,
  useGetVKGroupGeoPhotosQuery,
  useGetVKSearchCapacityQuery,

  useCreateVKSearchByNumbersMutation,
  endpoints: {
    getVKProfiles: { useQueryState: useGetVKProfilesQueryState },
    getVKGroupSubscribers: { useQueryState: useGetVKGroupSubscribersQueryState },
  },
} = vkApi;
