import { DossierSocialConnectorId, SourceId } from 'enums/dossier';
import { Operations, Schemas } from 'types/base.type';

export enum SearchStatus {
  NEW = 0,
  QUEUE = 1,
  PROCESSING = 2,
  DONE = 3,
}

export enum SearchByPhotosProvider {
  facehunter = 'facehunter',
  shukai = 'shukai',
  orcface = 'orcface',
  clearview = 'clearview',
}

// success codes [SUCCESS, SUCCESS_WITH_ERRORS, SUCCESS_NO_RESULTS];
export enum PhotoProcessingStatus {
  // value for usage in models for old data that doesn't have PhotoRequestStatus
  NOT_SET = -1,

  // Success codes [0-15] : results was successful
  SUCCESS = 0,

  // Request for at least one face failed
  SUCCESS_WITH_ERRORS = 1,

  // Processed by no results found
  SUCCESS_NO_RESULTS = 2,
  // TODO: SHUKAI: No similar faces found has the same value
  // SUCCESS_NO_FACES = 2,

  // Error codes : client [16-31]
  // Request are not allowed to be made
  // Possible reasons: access from Europe (not USA or Ukraine), ...
  REQUEST_FORBIDDEN = 16,

  CONNECTION_ERROR = 17,

  // Can sporadically appear when too many requests are made simultaneously
  TOO_MANY_REQUESTS = 18,

  // Some photos can't be processed due to this strange error - it CAN'T be fixed
  UNAVAILABLE_FOR_LEGAL_REASONS = 19,

  REQUEST_TIMEOUT = 20,

  CONFLICT = 21,

  // Usually it signals about malformed request (invalid parameters, etc...)
  BAD_REQUEST = 22,

  // This is "Unauthorized" error
  // Usually reason - we lost access or api key became incorrect
  ACCESS_DENIED = 23,

  // Error codes : server [32-63]
  INVALID_FORMAT = 32,

  // Sometimes Clearview can return html (timeout problem)
  INVALID_CONTENT_TYPE = 33,

  BAD_GATEWAY = 34,
  INTERNAL_SERVER_ERROR = 35,
  SERVICE_UNAVAILABLE = 36,

  UNKNOWN_ERROR = 63,

  // TODO: TEMP Shukai statuses

  // Target for face recognition invalid (invalid url, etc...)
  FETCH_TARGET_ERROR = 8,

  // Authentication token is no longer anymore vali
  AUTHENTICATION_ERROR = 9,

  // Authentication failed : Invalid login or password
  FORBIDDEN = 10,

  // Invalid target url or blob
  INVALID_TARGET = 11,

  // Can't process this blob or url
  UNPROCESSABLE_TARGET = 12,
}

export enum PhotoSimilarityLinkDataType {
  vk = 'vk',
  ok = 'ok',
  telegram = 'telegram',
}

export type PhotoSimilarityRect = {
  height: number;
  width: number;
  xTop: number;
  yTop: number;
};

type SearchResult = {
  resultCode: PhotoProcessingStatus;
  resultFaces: PhotoSimilarity[][];
  sourceFaces: { id: string; bbox: PhotoSimilarityRect }[];
};

type UserPhoto = {
  photo: string;
  isVideo?: boolean;
};

type PhotoSimilarityImageData = {
  originalUrl: string;
  width: number;
  height: number;
  url: string;
};

type PhotoSimilarityProfileData = {
  name: string;
  bio: string;
  link: string;
  countryName: string;
  cityName: string;
  dateOfBirth: string;
  sex: number;
};

export type PhotoSimilarityLinkData = {
  link: string;
  title: string;
  description: string;
  type: PhotoSimilarityLinkDataType | string | null;
  id: number;
  alias: string;
};

type PhotoSimilarityMatchData = {
  similarity: number;
  bbox: PhotoSimilarityRect;
};

export type PhotoSimilarity = {
  key: string;
  imageData: PhotoSimilarityImageData;
  linkData: PhotoSimilarityLinkData;
  profileData?: PhotoSimilarityProfileData;
  matchData: PhotoSimilarityMatchData;
};

type SearchResultDataItemResponse = {
  searchResult: SearchResult;
  userPhoto: UserPhoto & { file?: string };
  provider?: SearchByPhotosProvider;
};

export type CreateSearchResponse = {
  id: number;
  userPhotoIds: number[];
  status: SearchStatus;
  resultsData: Record<string, SearchResultDataItemResponse> | null;
  name?: string;
  comment?: string;
  provider?: SearchByPhotosProvider;
};

export type SearchesListItem = {
  id: number;
  status: SearchStatus;
  sourcePhotos: SourcePhoto[];
  name?: string;
  comment?: string;
  providers?: SearchByPhotosProvider[];
  createdAt?: string;
  updatedAt?: string;
};

export type GetAllSearchesRequest = {
  name?: string;
  comment?: string;
  createdByMe?: boolean;
  createdAtGte?: string;
  createdAtLte?: string;
  hasManualPhotos?: boolean;
  page: number;
  pageSize: number;
};

export enum SourcePhotosType {
  telegram = DossierSocialConnectorId.TELEGRAM,
  vk = DossierSocialConnectorId.VKONTAKTE,
  mamba = DossierSocialConnectorId.MAMBA,
  dossier = 100,
  manual = 12,
}

export type FaceRecognizablePhoto = {
  id: number;
  path?: string;
  source: { id: SourceId };
};

export type CreateSearchByPhotosProps = Operations['FaceSearchPost']['parameters']['query'];

export type SimilarPhotoSourceFace = {
  providerFaceId: string;
  bbox: PhotoSimilarityRect;
};

export type SimilarFaces = Record<
  SearchByPhotosProvider,
  {
    code: PhotoProcessingStatus;
    faces: PhotoSimilarity[];
  }
>;

export type SimilarPhoto = {
  sourceFace: SimilarPhotoSourceFace;
  similarFaces: SimilarFaces;
};

export type SimilarFacesGroupedByProfile = {
  profile: PhotoSimilarityLinkData;
  similarFaces: SimilarFaces;
};

export type SearchPhotoResult = {
  sourcePhoto: SourcePhoto;
  similarPhotos: SimilarPhoto[];
  code:
    | PhotoProcessingStatus.SUCCESS
    | PhotoProcessingStatus.SUCCESS_WITH_ERRORS
    | PhotoProcessingStatus.SUCCESS_NO_RESULTS;
};

export type SourcePhoto = Schemas['PhotoSource'];

export type SearchByPhotosResult = {
  id: number;
  status: SearchStatus;
  name?: string;
  comment?: string;
  createdAt: string;
  updatedAt: string;
  sourcePhotos: SourcePhoto[];
  providers: SearchByPhotosProvider[];
  results: SearchPhotoResult[] | null;
};

export type SearchPhotoResultFilter = {
  providers: SearchByPhotosProvider[];
  groupByAccounts: boolean;
  onlyHighSimilarity: boolean;
};
