import { useState, useMemo, useCallback, useEffect, ChangeEvent, useDeferredValue } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { uniqBy } from 'lodash';

import { useGetDossierQuery, useUpdateDossierRelatedTagsMutation } from 'api/services/dossier';
import { useGetUserTagsQuery } from 'api/services/tags';
import { mutationTriggerDefaultHandler } from 'api/utils';
import { NOTIFICATION } from 'components/Alert/constants';
import { useModal, useConfirmDialog } from 'contexts/ModalContext';
import { ModalNames } from 'contexts/ModalContext/enums';
import { PlausibleEvents } from 'enums/plausibleAnalytics';
import { usePlausibleAnalytics } from 'hooks';
import { useAggregatedTags } from 'hooks/useAggregatedTags';
import { BandTag } from 'types/tags.type';
import { displayErrorMessages } from 'utils';
import { isArraysEqual } from 'utils/isEqual';

import { CONFIRM_TEXT } from './constants';
import { ManageUserTagModalProps, UserInfoData, UseManageUserTagsReturnType } from './types';

export const useManageUserTags = ({
  bandId,
  prevTagIdsState,
}: ManageUserTagModalProps): UseManageUserTagsReturnType => {
  const { data: tagsList, error: loadTagListError } = useGetUserTagsQuery({ addableOnly: true });
  const {
    tags,
    isLoading: isUserTagsLoading,
    error: loadUserTagsError,
  } = useAggregatedTags({ bandIds: [bandId] });

  const userTags = tags?.[bandId];

  const {
    data: user,
    isLoading: isUserLoading,
    error: loadUserError,
  } = useGetDossierQuery(bandId ?? skipToken);

  const [updateDossierTags, { isLoading: isDossierTagsUpdating }] =
    useUpdateDossierRelatedTagsMutation();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(() => prevTagIdsState || []);
  const [searchValue, setSearchValue] = useState<string>('');
  const searchDeferredValue = useDeferredValue<string>(searchValue);

  const { closeModal, openModal } = useModal();
  const confirm = useConfirmDialog();
  const { plausibleEvent } = usePlausibleAnalytics();

  const error = loadTagListError || loadUserError || loadUserTagsError;

  const initialUserTagsIds: number[] = useMemo(() => {
    return userTags ? userTags.map((tag: BandTag) => tag.id) : [];
  }, [userTags]);

  const isUserTagsHasChanges: boolean = useMemo(() => {
    return !isArraysEqual(selectedTagIds, initialUserTagsIds);
  }, [selectedTagIds, initialUserTagsIds]);

  const isTagAddable = useCallback(
    (tag: BandTag) => !!tagsList?.some((t) => t.id === tag.id),
    [tagsList]
  );

  const availableTags: BandTag[] = useMemo(
    () => tagsList?.filter((tag) => !selectedTagIds.includes(tag.id) && isTagAddable(tag)) || [],
    [tagsList, selectedTagIds]
  );

  const selectedTags: BandTag[] = useMemo(
    () =>
      uniqBy([...(userTags ?? []), ...(tagsList ?? [])], 'id').filter((tag) =>
        selectedTagIds.includes(tag.id)
      ) || [],
    [tagsList, userTags, selectedTagIds]
  );

  const matchedTags: BandTag[] = useMemo(() => {
    if (!availableTags.length || searchDeferredValue === '') return availableTags;
    return availableTags.filter((tag) =>
      tag.name.toLowerCase().includes(searchDeferredValue.toLowerCase())
    );
  }, [availableTags, searchDeferredValue]);

  const userInfo: UserInfoData | null = useMemo(() => {
    if (!user) return null;

    const { firstName, lastName, avatarInfo } = user;

    const firstNameValue = (typeof firstName === 'object' ? firstName?.value : firstName) ?? '';
    const lastNameValue = (typeof lastName === 'object' ? lastName?.value : lastName) ?? '';

    const fullName = `${firstNameValue} ${lastNameValue}`;
    const avatar = avatarInfo ? { ...avatarInfo, isMain: true } : null;

    return { fullName, avatar };
  }, [user]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value.trim());
  }, []);

  const handleTagClick = useCallback(
    (tagId: number): void => {
      const mergedTags: BandTag[] =
        tagsList?.map((tag) => {
          const userTag = userTags?.find((item) => item.id === tag.id);
          return userTag ? { ...tag, ...userTag } : tag;
        }) ?? [];

      const tag = mergedTags.find((tag) => tag.id === tagId);

      setSelectedTagIds((prev) => {
        const isAlreadySelected = prev.includes(tagId);

        if (!isAlreadySelected && tag && isTagAddable(tag)) {
          return [...prev, tagId];
        }

        if (isAlreadySelected && tag?.removable) {
          return prev.filter((id) => id !== tagId);
        }
        return prev;
      });
    },
    [tagsList, userTags, isTagAddable]
  );

  const handleReset = useCallback(() => {
    setSelectedTagIds(initialUserTagsIds);
    setSearchValue('');
    plausibleEvent(PlausibleEvents.MANAGE_USER_TAGS_RESET);
  }, [initialUserTagsIds]);

  const handleClose = useCallback(() => {
    if (!isUserTagsHasChanges) return closeModal();

    return confirm({
      title: CONFIRM_TEXT.leave.title,
      description: CONFIRM_TEXT.leave.description,
      submitButtonText: CONFIRM_TEXT.leave.submitButtonText,
      cancelButtonText: CONFIRM_TEXT.leave.cancelButtonText,
      onConfirm: () => {
        plausibleEvent(PlausibleEvents.MANAGE_USER_TAGS_CLOSE);
      },
      onCancel: () =>
        openModal(ModalNames.createUserTag, { bandId, prevTagIdsState: selectedTagIds }),
    });
  }, [isUserTagsHasChanges, selectedTagIds]);

  const handleSave = useCallback(async (): Promise<BandTag[] | void> => {
    confirm({
      title: CONFIRM_TEXT.save.title,
      description: CONFIRM_TEXT.save.description,
      submitButtonText: CONFIRM_TEXT.save.submitButtonText,
      cancelButtonText: CONFIRM_TEXT.save.cancelButtonText,
      onConfirm: () => {
        mutationTriggerDefaultHandler(
          updateDossierTags({
            dossierId: bandId,
            tagIds: selectedTagIds.filter((id) => !!tagsList?.some((t) => t.id === id)),
          }),
          NOTIFICATION.TAG_USER_SUCCESS
        );
        plausibleEvent(PlausibleEvents.MANAGE_USER_TAGS_SAVE);
      },
      onCancel: () =>
        openModal(ModalNames.createUserTag, { bandId, prevTagIdsState: selectedTagIds }),
    });
  }, [selectedTagIds, tagsList]);

  useEffect(() => {
    if (!error) return;
    displayErrorMessages(error, NOTIFICATION.SOMETHING_WRONG);
  }, [error]);

  useEffect(() => {
    if (selectedTagIds.length) return;

    if (initialUserTagsIds.length) {
      setSelectedTagIds(initialUserTagsIds);
    }
  }, [initialUserTagsIds]);

  useEffect(() => {
    plausibleEvent(PlausibleEvents.MANAGE_USER_TAGS_OPEN_MODAL);
  }, []);

  return {
    searchValue: searchDeferredValue,
    userInfo,
    isUserLoading,
    isUserTagsLoading,
    selectedTags,
    matchedTags,
    isUserTagsUpdating: isDossierTagsUpdating,
    isUserTagsHasChanges,

    handleInputChange,
    handleTagClick,
    handleReset,
    handleClose,
    handleSave,
  };
};
