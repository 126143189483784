import dayjs from 'dayjs';

import { DATE_TIME_FORMAT } from 'constants/date';
import type { BandProfile } from 'types/dossier.type';
import { DossierSearchHit } from 'types/dossierSearch.type';
import { UserTrajectory } from 'types/personProfile.type';
import { BandTag, CountryRegionTag } from 'types/tags.type';
import { TrajectoryItem } from 'types/userProfile.type';

enum TrajectoryCountry {
  UKRAINE = 'Україна',
  BELARUS = 'Білорусь',
  MOLDOWA = 'Молдова',
  RASHKA = 'Росія',
  RU = 'Російська Федерація',
}

const COUNTRY_CODE = {
  UKRAINE: 'UA',
  MOLDOWA: 'MD',
  BELARUS: 'BY',
  RASHKA: 'RU',
};

export const dossierSearchHitToBandProfile = (dossier: DossierSearchHit): BandProfile => {
  const {
    id,
    firstName,
    lastName,
    middleName,
    photos,
    tags,
    countryRegionTagCounts,
    avatarInfo,
    militaryScore,
    lastLocations,
    lastMilitaryExperience,
    connectors,
    naisSummary,
    phones,
    prominLink,
  } = dossier;

  const tgConnector = dossier.connectors.find((connector) => connector.id === null);
  const name = [lastName?.value, firstName?.value, middleName?.value].filter(Boolean).join(' ');

  return {
    tgUserId: tgConnector?.profileId || 0,
    id,
    photos,
    userTags: tags,
    countryRegionTagCounts: countryRegionTagCounts ?? [],
    avatarInfo: avatarInfo ? { ...avatarInfo, isMain: true } : undefined,
    naisSummary,
    name,
    username: tgConnector?.username || '',
    phones,
    wasOnline: lastLocations[0]?.onlineStatus?.whenWasOnline ?? null,
    onlineStatus: lastLocations[0]?.onlineStatus?.title ?? null,
    lastLocations,
    militaryScore,
    lastMilitaryExperience,
    connectors,
    prominLink,
  };
};

export const dossierSearchHitToAggregatedBandProfile = (
  dossier: DossierSearchHit,
  tags: BandTag[] | null | undefined,
  geotags: CountryRegionTag[] | null | undefined
): BandProfile => ({
  ...dossierSearchHitToBandProfile(dossier),
  ...(tags && { userTags: tags }),
  ...(geotags && {
    countryRegionTagCounts: geotags.map((geotag) => ({
      count: 0,
      countryRegionTag: geotag,
    })),
  }),
});

const getTrajectoryCountryCode = (country: string) => {
  switch (country) {
    case TrajectoryCountry.UKRAINE:
      return COUNTRY_CODE.UKRAINE;
    case TrajectoryCountry.BELARUS:
      return COUNTRY_CODE.BELARUS;
    case TrajectoryCountry.MOLDOWA:
      return COUNTRY_CODE.MOLDOWA;
    case TrajectoryCountry.RASHKA:
      return COUNTRY_CODE.RASHKA;
    case TrajectoryCountry.RU:
      return COUNTRY_CODE.RASHKA;
    default:
      return country;
  }
};

export const transformTrajectory = (userTrajectory: TrajectoryItem[]): UserTrajectory[] => {
  const MAX_RADIUS = 18;

  const trajectory = userTrajectory.map((location, index) => {
    const { name, country, centroid, clusters } = location;
    const [lat, lng] = centroid;

    const currentClusters = [...clusters].sort((a, b) =>
      dayjs(a.parsedAt).isAfter(dayjs(b.parsedAt)) ? 1 : -1
    );
    const countClusters = clusters.length;
    const startDate = currentClusters[0].parsedAt;
    const endDate = currentClusters[countClusters - 1].parsedAt;

    const maxRadius = clusters.reduce(
      (max, cluster) => Math.max(max, cluster.areaRadius),
      Number.MIN_VALUE
    );
    const radius = Number(((maxRadius / 1000) * 1.5).toFixed(6));

    const startDateTime = dayjs(startDate).format(DATE_TIME_FORMAT);
    const endDateTime = dayjs(endDate).format(DATE_TIME_FORMAT);

    return {
      id: clusters[clusters.length - 1].scanId,
      year: dayjs(endDate).year(),
      order: index + 1,
      country: getTrajectoryCountryCode(country),
      region: name ?? '[Не визначено]',
      centroid: [Number(lat.toFixed(6)), Number(lng.toFixed(6))],
      radius: radius > MAX_RADIUS ? MAX_RADIUS : radius,
      countClusters: clusters.length,
      dateTimes: `${startDateTime} - ${endDateTime}`,
      startDate,
      endDate,
    } as UserTrajectory;
  });

  return trajectory.sort((a, b) => b.order - a.order);
};
