import { SearchByPhotosProvider } from './types';

export const NOT_AVAILABLE_TOOLTIP_TITLE = 'Сервіс розпізнавання наразі недоступний';

export const SEARCH_BY_PHOTOS_PROVIDER_LABELS = {
  [SearchByPhotosProvider.clearview]: 'ClearView',
  [SearchByPhotosProvider.facehunter]: 'FaceHunt',
  [SearchByPhotosProvider.shukai]: 'ShukAi',
  [SearchByPhotosProvider.orcface]: 'Orcface',
} as const;

export const HIGH_PHOTO_SIMILARITY = 0.8;

export const MIDDLE_PHOTO_SIMILARITY = 0.6;
