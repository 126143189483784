import { DossierSocialConnectorId, SourceId } from 'enums/dossier';
import { DossierSearchHit } from 'types/dossierSearch.type';

export const isDossierPhoto = (sourceId?: SourceId) => sourceId === SourceId.DOSSIER;

export const extractConnectorIds = (
  bands: DossierSearchHit[] | undefined,
  connectorTypeId: DossierSocialConnectorId
) => {
  return bands
    ?.flatMap(({ connectors }) => connectors)
    .filter(({ connectorType, profileId }) => connectorType.id === connectorTypeId && profileId)
    .map(({ profileId }) => profileId as number);
};
