export enum SearchSlug {
  personal = 'personal_info',
  tags = 'tags',
}

export enum SearchTypes {
  points = 'points',
}

export enum SearchUseCase {
  location = 1,
  guardian = 2,
}

export enum SearchStatus {
  CREATED = 'Створений',
  QUEUED = 'В черзі',
  PROCESSING = 'В процесі обробки',
  READY = 'Готово',
  ERROR = 'Помилка',
  CANCELLED = 'Скасовано',
}

export enum SearchRequestStatus {
  NEW = 0,
  QUEUE = 1,
  PROCESSING = 2,
  DONE = 3,
  DOWNLOADED = 4,
  ERROR = 5,
  ERROR_NO_SUCH_CHANNEL = 6,
  ERROR_CHANNEL_WITHOUT_COMMENTS = 7,
  CANCELLING = 8,
  CANCELLED = 9,
}

export enum VKSearchRequestStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum MarkerForSearch {
  GREEN = 0,
  RED = 1,
  YELLOW = 2,
  NULLISH = 3,
}

export enum ProcessingStatus {
  NOT_PROCESSED = 0, // Не опрацьовано
  IN_PROGRESS = 1, // В роботі
  READY = 2, // Опрацьовано
  CANCELLED = 3, // Скасовано
  CANCELLING = 4, // Скасовується
}

export enum AccountTypes {
  ALL = 'ALL',
  NEW_ONLY = 'NEW_ONLY',
  PERIODIC_NEW_ONLY = 'PERIODIC_NEW_ONLY',
}
