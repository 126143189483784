import { merge, pick } from 'lodash';

import { DossierSocialConnectorId } from 'enums/dossier';
import { ConnectorFilter } from 'types/dossierSearch.type';

import { HIGH_PHOTO_SIMILARITY } from './constants';
import {
  PhotoProcessingStatus,
  SearchByPhotosProvider,
  SearchPhotoResult,
  SearchPhotoResultFilter,
  SimilarPhoto,
  SimilarFaces,
  SimilarFacesGroupedByProfile,
  PhotoSimilarityLinkData,
  PhotoSimilarity,
  PhotoSimilarityLinkDataType,
} from './types';

type LinkData = {
  type: PhotoSimilarityLinkDataType | string | null;
  id?: number;
  alias?: string;
};

const HIGH_PHOTO_SIMILARITY_FILTER = 0.5999;

export const getHostFromUrl = (url: string) => {
  if (!url) {
    return {};
  }
  let link = {} as URL;
  try {
    link = new URL(url);
  } catch {
    console.error('Parse URL error', url);
  }
  const host = link?.host?.toLowerCase().replace(/^www./, '');
  return { host, origin: link.origin };
};

export const isVkLink = (link: string) => {
  if (!link) {
    return false;
  }
  const host = getHostFromUrl(link)?.host || '';
  return /(vk|vkontakte)\.(\w+[^.])$/g.test(host.toLowerCase());
};

export const getVkIdFromLink = (link: string): string | undefined => {
  if (!isVkLink(link)) {
    return undefined;
  }
  const matchId = link.match(/photo(\d+)/)?.[1] || link.match(/video(\d+)/)?.[1];
  if (matchId) {
    return matchId;
  }
  const matchUserName = link.match(/.*[vk|vkontakte]\..+\/([\w.]+[^/])$/)?.[1];
  return matchUserName;
};

export const getMaxSimilarity = (similarFaces: SimilarFaces) => {
  return Array.from(Object.entries(similarFaces)).reduce<{
    provider: string | null;
    similarity: number;
  }>(
    (accum, [provider, { faces = [] }]) => {
      const sortedFaces = [...faces].sort(
        (a, b) => b.matchData.similarity - a.matchData.similarity
      );
      const face = sortedFaces[0];
      if (face?.matchData?.similarity > accum.similarity) {
        accum.provider = provider;
        accum.similarity = face.matchData.similarity;
      }
      return accum;
    },
    { provider: null, similarity: 0 }
  );
};

export const getPhotoProcessingResponseCodeMessage = (code?: PhotoProcessingStatus) => {
  if (!code) {
    return '';
  }
  switch (code) {
    case PhotoProcessingStatus.CONNECTION_ERROR:
    case PhotoProcessingStatus.TOO_MANY_REQUESTS:
    case PhotoProcessingStatus.REQUEST_TIMEOUT:
      return 'Сталася помилка, спробуйте пізніше!';
    case PhotoProcessingStatus.UNAVAILABLE_FOR_LEGAL_REASONS:
      return 'Недоступно з правових причин!';
    case PhotoProcessingStatus.ACCESS_DENIED:
      return 'Сервіс недоступний!';
    case PhotoProcessingStatus.SUCCESS_NO_RESULTS:
      return 'Результати відсутні!';
    case PhotoProcessingStatus.SUCCESS_WITH_ERRORS:
    case PhotoProcessingStatus.CONFLICT:
    case PhotoProcessingStatus.INVALID_FORMAT:
    case PhotoProcessingStatus.INVALID_CONTENT_TYPE:
    case PhotoProcessingStatus.BAD_GATEWAY:
    case PhotoProcessingStatus.INTERNAL_SERVER_ERROR:
    case PhotoProcessingStatus.SERVICE_UNAVAILABLE:
    case PhotoProcessingStatus.UNKNOWN_ERROR:
    default:
      return 'Сталася помилка при отриманні результатів!';
  }
};

export const similarPhotosToPhotosList = (similarPhotos: Pick<SimilarPhoto, 'similarFaces'>[]) => {
  return similarPhotos.flatMap(({ similarFaces }) =>
    Object.values(similarFaces).flatMap((similarFace) => similarFace.faces)
  );
};

export const getRecognitionCounts = (similarPhotos: Pick<SimilarPhoto, 'similarFaces'>[]) => {
  const photos = similarPhotosToPhotosList(similarPhotos || []);
  let exact = 0;
  const accountsSet = new Set<string>();
  photos.forEach(({ matchData, linkData }) => {
    if (matchData.similarity >= HIGH_PHOTO_SIMILARITY) exact++;
    accountsSet.add(`${linkData.type}-${linkData.id}`);
  });

  return {
    exact,
    accounts: accountsSet.size,
    photos: photos.length,
  };
};

export const filterSearchResults = (
  searchResults: SearchPhotoResult[],
  filter: SearchPhotoResultFilter
) => {
  if (!searchResults) return searchResults;
  return searchResults.map((searchResult) => {
    const searchResultCopy = merge({}, searchResult);
    searchResultCopy.similarPhotos = searchResultCopy.similarPhotos.map((similarPhoto) => {
      similarPhoto.similarFaces = pick(similarPhoto.similarFaces, filter.providers);
      if (filter.onlyHighSimilarity) {
        Object.entries(similarPhoto.similarFaces).forEach(([provider, similarFaces]) => {
          similarPhoto.similarFaces[provider as SearchByPhotosProvider] = {
            ...similarFaces,
            faces: similarFaces.faces.filter(
              (item) => item.matchData.similarity >= HIGH_PHOTO_SIMILARITY_FILTER
            ),
          };
        });
      }
      return similarPhoto;
    });
    return searchResultCopy;
  });
};

export const groupSimilarFacesByProfiles = (
  similarFaces: SimilarFaces
): SimilarFacesGroupedByProfile[] => {
  const resultMap = new Map<string, SimilarFacesGroupedByProfile>();
  Object.entries(similarFaces).forEach(([provider, entity]) => {
    entity.faces.forEach((face) => {
      const key = `${face.linkData.type}-${face.linkData.id}`;
      if (!resultMap.has(key)) {
        resultMap.set(key, {
          profile: face.linkData,
          similarFaces: Object.keys(similarFaces).reduce((accum, key) => {
            accum[key as SearchByPhotosProvider] = { code: entity.code, faces: [] };
            return accum;
          }, {} as SimilarFaces),
        });
      }
      resultMap.get(key)?.similarFaces[provider as SearchByPhotosProvider].faces.push(face);
    });
  });
  return Array.from(resultMap.values()).toSorted(
    (a, b) =>
      getMaxSimilarity(b.similarFaces).similarity - getMaxSimilarity(a.similarFaces).similarity
  );
};

export const getConnectorTypeByLinkDataType = (linkDataType: string | null) => {
  switch (linkDataType) {
    case 'telegram':
      return DossierSocialConnectorId.TELEGRAM;
    case 'vk':
      return DossierSocialConnectorId.VKONTAKTE;
    case 'ok':
      return DossierSocialConnectorId.ODNOKLASSNIKI;
    default:
      return DossierSocialConnectorId.UNKNOWN;
  }
};

export const getConnectorDataByLinkData = (linkData: PhotoSimilarityLinkData) => {
  if (linkData.type) {
    return {
      id: linkData.id,
      type: getConnectorTypeByLinkDataType(linkData.type),
    };
  }
  if (isVkLink(linkData.link)) {
    return {
      id: Number(getVkIdFromLink(linkData.link)),
      type: DossierSocialConnectorId.VKONTAKTE,
    };
  }
  return null;
};

export const createConnector = ({ type, id, alias }: LinkData): ConnectorFilter => {
  return {
    connectorType: getConnectorTypeByLinkDataType(type),
    ...(id && { profileId: id }),
    ...(alias && { nickname: alias }),
  };
};

export const createConnectorKey = ({
  connectorType,
  profileId,
  nickname,
}: ConnectorFilter): string =>
  `connectorType:${connectorType};profileId:${profileId};nickname:${nickname}`;

export const getSearchPhotoResultConnectorFilters = (
  results: SearchPhotoResult[] | null | undefined
): ConnectorFilter[] => {
  const connectorsMap = new Map<string, ConnectorFilter>();
  results?.forEach((searchPhotoResult) => {
    return searchPhotoResult.similarPhotos.forEach(({ similarFaces }) => {
      return Object.entries(similarFaces).forEach(([, { faces }]) => {
        return faces.forEach(({ linkData }) => {
          const connector = createConnector(linkData);
          const connectorKey = createConnectorKey(connector);
          connectorsMap.set(connectorKey, connector);
        });
      });
    });
  });
  return Array.from(connectorsMap.values());
};

export const isAllowToLinkBands = (linkData: PhotoSimilarityLinkData) =>
  (linkData.id &&
    linkData.type &&
    linkData.type !== PhotoSimilarityLinkDataType.telegram &&
    getConnectorTypeByLinkDataType(linkData.type) !== DossierSocialConnectorId.UNKNOWN) ||
  isVkLink(linkData.link); // needed for ClearView

export const forEachPhotoSimilarity = (
  searchPhotoResult: SearchPhotoResult[] | null | undefined,
  callback: (face: PhotoSimilarity) => void
) => {
  searchPhotoResult?.forEach((item) => {
    item.similarPhotos.forEach((similarPhoto) => {
      Object.values(similarPhoto.similarFaces).forEach((sourceFace) => {
        sourceFace.faces.forEach((face) => {
          callback(face);
        });
      });
    });
  });
};
